.quizes {
	width: 90%;
	.quizes__block {
		cursor: pointer;
		background: white;
		border-radius: 20px;
		margin-top: 10px;
		min-height: 80px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.quizes__header {
			font-size: 14px;
			font-weight: 500;
			text-align: center;
			font-family: 'Helvetica Neue', sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 111.4%;
			color: #1a1a1a;
		}
		.quizes__value {
			font-size: 14px;
			text-align: center;
			margin: 0;
		}
	}
	.active__quiz {
		border: 3px solid #cd3374;
		box-sizing: border-box;
	}
}
.quiz__button {
	position: absolute;
	bottom: 0;
	margin-bottom: 70px;
}
