.hero {
	position: fixed;
	bottom: -250px;

	&--left {
		&-show {
			left: -125px;

			animation-name: show-left-hero;
			animation-duration: 700ms;
			animation-iteration-count: 1;
			animation-fill-mode: forwards;
		}

		&-hide {
			animation-name: hide-left-hero;
			animation-duration: 700ms;
			animation-iteration-count: 1;
			animation-fill-mode: forwards;
		}
	}

	&--right {
		&-show {
			right: -125px;

			transform: scale(-1, 1);

			animation-name: show-right-hero;
			animation-duration: 700ms;
			animation-iteration-count: 1;
			animation-fill-mode: forwards;
		}

		&-hide {
			transform: scale(-1, 1);

			animation-name: hide-left-hero;
			animation-duration: 700ms;
			animation-iteration-count: 1;
			animation-fill-mode: forwards;
		}
	}

	@media screen and (min-height: 550px) {
		bottom: -300px;
	}

	@media screen and (min-height: 660px) {
		bottom: -250px;
	}

	@media screen and (min-height: 715px) {
		bottom: -200px;
	}

	@media screen and (min-height: 800px) {
		bottom: -150px;
	}

	&__image {
		width: 450px;
	}
}
