.success-screen {
	width: 100%;
	text-align: center;

	display: flex;
	flex-direction: column;
	align-items: center;

	font-family: 'Nunito', sans-serif;
	color: #fff;

	&__image {
		padding: 70px 48px 0;
		margin-bottom: 25px;

		width: 100%;
		max-width: 375px;
	}

	.download-links {
		margin: 0 auto 41px;
		padding: 0 20px;

		max-width: 400px;

		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 15.16px;

		.link {
			&-image {
				width: 100%;
			}
		}
	}

	&__title {
		margin-top: 0;
		margin-bottom: 4px;

		font-weight: 800;
		font-size: 40px;
		line-height: 120%;
	}

	&__subtitle {
		margin-top: 0;
		margin-bottom: 10px;

		font-weight: bold;
		font-size: 30px;
		line-height: 120%;
	}

	&__description {
		margin-top: 0;
		margin-bottom: 40px;
		padding: 0 24px 30px;

		width: 100%;

		font-size: 15px;
		line-height: 25px;

		border-bottom: 1px solid rgba(34, 36, 39, 0.1);
	}

	&-step {
		margin: 19px 0;

		display: flex;
		flex-direction: column;
		align-items: center;

		.step {
			&__title {
				margin: 0 0 20px;
				padding: 4px 17px;

				width: 100%;
				max-width: 103px;

				font-weight: 600;
				font-size: 22px;
				line-height: 130%;

				background: linear-gradient(180deg, #df2f64 0%, #b81b6b 100%);
				box-shadow: inset 0px 2px 1px #f04294, inset 0px -3px 2px #c4267e;
				border-radius: 25px;
			}

			&__description {
				margin: 0;
				padding: 0 39px;

				max-width: 315px;

				font-weight: bold;
				font-size: 18px;
				line-height: 23px;
				letter-spacing: 0.25px;
			}

			&__image {
				margin: 24px 0 41px;

				width: 100%;
				max-width: 400px;
			}
		}
	}
}
