.question__control {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	.control__button {
		height: 42px;
		width: 216px;
		border: 2px solid #d34e7e;
		border-radius: 21px;
		background: linear-gradient(#db5186, #c6236a);
		z-index: 0;
		font-family: 'Nunito Sans', sans-serif;
		display: flex;
		justify-content: center;
		align-items: center;
		color: white;
		text-decoration: none;
		position: absolute;
		bottom: 0;
		margin-bottom: 70px;
	}
}
