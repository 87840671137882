.story-background {
	width: 100%;
	height: 100vh;
	position: relative;

	background: no-repeat center center fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;

	.story {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;

		font-family: 'Nunito Sans', sans-serif;
	}
}
