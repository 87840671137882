.ready-container {
	.ready-container__img-dialog {
		position: absolute;
		top: 29.6%;
		width: 78%;
		right: 10px;
	}
	width: 100%;
	margin-top: 62px;
	height: auto;
	position: relative;
	.ready-container__img {
		width: 100%;
		height: auto;
	}
	.ready-container__text {
		position: absolute;
		right: 15%;
		width: 40%;
		top: 32.3%;
		text-align: center;
		font-family: 'Helvetica Neue', sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 13px;
		line-height: 25px;
		align-items: center;
		letter-spacing: 0.2px;
		text-transform: uppercase;
		color: #ffffff;
		text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
	}
	.ready-container__img-bottom {
		width: 100%;
		position: absolute;
		bottom: -1px;
	}
	.ready-container-character {
		position: absolute;
		width: 100%;
		height: 100%;
		.ready-container-character__img {
			position: absolute;
			height: auto;
			width: 77%;
			left: -40px;
			bottom: 0;
		}
	}
}
.secret-fantasy {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	background: #0e0f10;
	.secret-fantasy__img {
		width: 95%;
		margin-top: 37px;
	}
	.control__button {
		height: 42px;
		width: 71.6%;
		border: 2px solid #d34e7e;
		border-radius: 30px;
		margin: 0;
		position: static;
		background: linear-gradient(#db5186, #c6236a);
		font-family: 'Open Sans', sans-serif;
		text-align: center;
		color: white !important;
		text-decoration: none;
	}
	.secret-fantasy__img-wrapper {
		position: relative;
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: 14px;
		.control__button {
			height: 42px;
			width: 85%;
			border: 2px solid #d34e7e;
			border-radius: 30px;
			margin-bottom: 20px;
			position: static;
			background: linear-gradient(#db5186, #c6236a);
			font-family: 'Open Sans', sans-serif;
			text-align: center;
			color: white;
			text-decoration: none;
		}
	}
	.secret-fantasy__img-wrapper2 {
		position: relative;
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: 14px;
		.control__button {
			height: 46px;
			width: 80%;
			border: 2px solid #d34e7e;
			border-radius: 30px;
			margin-bottom: 20px;
			position: static;
			background: linear-gradient(#db5186, #c6236a);
			font-family: 'Open Sans', sans-serif;
			text-align: center;
			color: white !important;
			text-decoration: none;
		}
	}
	.premium__img {
		width: 80%;
		margin-top: 57px;
	}
	.countdown {
		width: 61%;
		padding: 14px;
		display: flex;
		flex-direction: column;
		align-items: center;
		border: 1px solid #e14e87;
		border-radius: 14px;
		p {
			margin: 0;
		}
		.timer {
			font-family: Lora;
			font-style: normal;
			font-weight: bold;
			font-size: 21px;
			line-height: 12px;
			display: flex;
			align-items: center;
			text-align: center;
			letter-spacing: 0.248359px;
			color: #ffffff;
		}
		.text {
			font-family: 'Open Sans';
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 17px;
			text-align: center;
			letter-spacing: 0.248359px;
			color: #ffffff;
			margin-top: 10px;
			opacity: 0.8;
		}
	}
	.features {
		width: 100%;
		display: flex;
		margin-top: 30px;
		margin-bottom: -20px;
		flex-direction: column;
		align-items: center;
		.feature {
			img {
				margin-right: 22px;
			}
			margin-bottom: 13px;
			width: 80%;
			display: flex;
			align-items: center;
			.feature__text {
				margin: 0;
				font-family: 'Helvetica Neue', sans-serif;
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 20px;
				letter-spacing: 0.248359px;
				color: #ffffff;
			}
		}
	}
}
.text-extralarge {
	margin: 0;
	font-family: 'Helvetica Neue', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 26px;
	line-height: 32px;
	width: 90%;
	margin-top: 63px;

	color: #ffffff;
}
.text-semilarge {
	width: 90%;
	margin: 0;
	font-family: 'Helvetica Neue', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 130%;
	margin-top: 30px;
	color: #ffffff;
}
.text-large {
	font-family: 'Helvetica Neue', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 120%;
	text-align: center;
	color: #ffffff;
	width: 90%;
}
.text-small {
	width: 90%;
	margin: 0;
	font-family: 'Helvetica Neue', sans-serif;
	font-style: normal;
	margin-top: 8px;
	font-weight: 500;
	font-size: 14px;
	line-height: 120%;
	color: #ffffff;
}
.text-medium {
	font-family: 'Helvetica Neue', sans-serif;
	font-style: normal;
	width: 90%;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	letter-spacing: 0.25px;
	color: #ffffff;
	opacity: 0.6;
}
button:focus {
	outline: none;
}
