.dialog-text {
	z-index: 2;
	display: flex;
	flex-direction: column;
	align-items: center;

	&--fade-in {
		animation-name: fade-in-dialog;
		animation-duration: 700ms;
		animation-delay: 700ms;
		animation-iteration-count: 1;
		animation-fill-mode: forwards;
	}

	&__character {
		padding-top: 6px;

		width: 100%;
		max-width: 169px;

		font-weight: 900;
		font-size: 13px;
		line-height: 25px;

		text-align: center;
		letter-spacing: 0.2px;
		text-transform: uppercase;

		color: var(--color--white);

		background: var(--color--linear-pink);

		border-radius: 30px 30px 0 0;
	}

	&__text {
		padding: 12.79px 17px 10.4px;

		width: 298px;

		background: #ffffff;
		border-radius: 25px;
		border: 1px solid rgb(141, 38, 55);

		@media screen and (max-width: 350px) {
			width: 260px;
		}

		p {
			margin: 0;

			font-weight: 600;
			font-size: 16px;
			line-height: 20px;

			span {
				opacity: 0;
				animation: move-text 1 forwards;
			}
		}
	}

	&__tooltip {
		position: relative;

		margin-top: 4px;

		font-family: 'Nunito', sans-serif;
		font-weight: 800;
		font-size: 14px;
		line-height: 19px;

		color: rgba(255, 255, 255, 0.7);

		&--bouncing {
			animation-name: tooltip-bouncing;
			animation-timing-function: linear;
			animation-duration: 700ms;
			animation-delay: 3.5s;
			animation-iteration-count: infinite;
		}
	}
}
