.button {
	padding: 16px 30px 15px;

	width: 100%;
	max-width: 335px;

	font-weight: 900;
	font-size: 18px;
	line-height: 104%;
	-webkit-text-stroke: 1px #7a17a8;
	color: #fff;

	text-shadow: 0px 2px 1px rgba(38, 22, 133, 0.22), 0px 0.8px 0px #7a17a8;

	background: linear-gradient(180deg, #e6366b 0%, #b81b6b 100%);
	box-shadow: inset 0px 2px 1px #f04294, inset 0px -3px 2px #c4267e;
	border-radius: 25px;

	&--disabled{
		&::after{
			content: ''; 
			position: absolute;
			z-index: 3;
			width: 100%; 
			height: 100%;
			background: rgba(0, 0, 0, 0.6);
			border-radius: 25px;
			top: 0; 
			left: 0;
		}
	}

	&:hover {
		cursor: pointer;
	}
}
