.sale-fantasy {
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;

	color: #fff;

	&__lock {
		margin: 10px 0 11px;

		width: 51px;
	}

	&__rating {
		margin-bottom: 15px;

		width: 26%;
		max-width: 375px;
	}

	&__title {
		margin: 0 31px 15px;

		font-weight: 900;
		font-size: 30px;
		line-height: 34px;

		text-align: center;
		-webkit-text-stroke: 1px #7a17a8;
		text-shadow: 0px 2px 1px rgba(38, 22, 133, 0.22), 0px 0.8px 0px #7a17a8;
	}

	&__subtitle {
		margin: 0 45px 37px;
		text-align: center;
		font-size: 16px;
		line-height: 22px;
	}

	&__description {
		margin: 0 34px 27px;

		line-height: 22px;

		text-align: center;
	}

	.video-wrapper {
		position: relative;

		width: 100%;
		max-width: 475px;

		&__frame {
			z-index: 3;

			width: 100%;
			height: 100%;
			max-height: 687px;
		}

		&__video {
			z-index: -1;

			position: absolute;
			top: 0;
			left: 0;

			padding: 28px 47px 0;

			width: 100%;
			height: 100%;

			border-radius: 59px 59px 0 0;
			object-fit: fill;
		}

		.button-wrapper {
			position: absolute;
			bottom: 0;
			left: 0;

			width: 100%;

			display: flex;
			justify-content: center;

			.button {
				max-width: 295px;
			}
		}
	}

	.discount-fantasy__title {
		margin: 0 20px 27px;

		max-width: 302px;

		font-weight: 900;
		font-size: 26px;
		line-height: 32px;

		text-align: center;
		-webkit-text-stroke: 1px #7a17a8;
		text-shadow: 0px 2px 1px rgba(38, 22, 133, 0.22), 0px 0.8px 0px #7a17a8;
	}
}
