.amount {
	width: 90%;
	border-radius: 12px;
	margin-top: 30px;
	max-width: 480px;
}
.billing__img {
	width: 100%;
	background: #f7f8fb;
	border-radius: 12px;
	margin-top: 30px;
	padding: 2px;
	box-sizing: border-box;
	box-shadow: 0px 6px 21px rgba(147, 147, 147, 0.11);
}
.rccs__card--unknown > div {
	background: url('./cardbg.png') !important;
	background-size: cover !important;
}
.card-info {
	width: 94%;
	display: flex;
	padding: 15px;
	padding-top: 20px;
	padding-left: 0px;
	padding-right: 0px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.card-info__number {
		width: 100%;
	}
}
.sc-gsTCUz {
	border: 2px solid silver;
	img {
		margin-bottom: 3px;
	}
}

.credit-card-input {
	margin-top: 5px;
	border-bottom: 1px solid#dbdee2 !important;
	text-align: center;
}
.input {
	label input {
		padding: 0px;
	}
}
.form-control-active {
	color: black !important;
}
.form-control-danger {
	border: 2px solid red;
	color: red !important;
}
.form-control-unactive {
	color: silver;
}
.form-control-danger {
	border: 2px solid red;
	color: red !important;
}

.form-group {
	.form-control {
		border-radius: 7px;
		font-family: Open Sans;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 22px;
		letter-spacing: 0.248359px;
	}
}
.form-control::placeholder {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.248359px;
	color: silver;
}

.secured {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 27px;
	text-align: center;
	letter-spacing: 0.248359px;
	color: #ffffff;
	opacity: 0.54;
	margin-top: 10px;
}

.failed-payment {
	text-align: center;
	color: red;
	margin-top: 10px;
	position: absolute;
	left: 0;
	right: 0;
	font-family: 'Open Sans';
	display: flex;
	justify-content: center;
	p {
		width: 60%;
		color: red;
		font-family: 'Open Sans';
	}
}
.checkout {
	background: #0e0e0e;
	height: 100vh;
	padding: 0px 30px 25px 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	.checkout__title {
		width: 100%;
		margin-top: 47px;
		margin-bottom: 0px;
		text-align: center;
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		line-height: 120%;
		text-align: center;
		color: #ffffff;
	}
	.amount__bill {
		display: flex;
		margin-top: 25px;
		flex-direction: column;
		.bill-row {
			margin-top: 25px;
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.bill-row__description {
				font-family: 'Open Sans';
				font-style: normal;
				font-weight: normal;
				font-size: 15px;
				line-height: 27px;
				text-align: center;
				letter-spacing: 0.248359px;
				color: #ffffff;
			}
			.bill-row__price {
				font-family: 'Open Sans';
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				line-height: 120%;
				text-align: center;
				color: #ffffff;
			}
		}
	}
}
.pay__button-active:focus {
	outline: none;
}
.pay__button-unactive:focus {
	outline: none;
}
.pay__button-active {
	margin-top: 35px;
	outline: none;
	background: linear-gradient(180deg, #db5186 0%, #c6236a 100%);
	border: 2px solid rgba(255, 255, 255, 0.2);
	box-sizing: border-box;
	border-radius: 30px;
	width: 100%;
	height: 48px;
	color: white;
}
.pay__button-unactive {
	outline: none;
	margin-top: 35px;
	background: linear-gradient(180deg, #db5186 0%, #c6236a 100%);
	border: 2px solid rgba(255, 255, 255, 0.2);
	box-sizing: border-box;
	border-radius: 30px;
	width: 100%;
	height: 48px;
	color: white;
	opacity: 0.6;
}
.numbers {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;
	letter-spacing: 0.248359px;
	color: #ffffff;
	margin: -7px 0px 15px 0px;
	opacity: 0.5;
}
