.sale-price {
	width: 100%;

	&__image {
		margin-bottom: 1px;

		width: 100%;
	}

	.price-info {
		margin: 0 auto;
		padding: 0 21px;

		width: 100%;
		max-width: 475px;

		display: flex;
		flex-direction: column;
		align-items: center;

		&__title {
			margin-bottom: 15px;

			font-weight: 900;
			font-size: 30px;
			line-height: 34px;
			text-align: center;

			-webkit-text-stroke: 1px #7a17a8;
			text-shadow: 0px 2px 1px rgba(38, 22, 133, 0.22), 0px 0.8px 0px #7a17a8;

			span {
				color: #d12a6b;
			}
		}

		&__subtitle {
			margin-bottom: 20px;

			font-weight: 800;
			font-size: 20px;
			line-height: 31px;
		}

		&__bottom-description {
			margin-bottom: 41px;

			max-width: 400px;

			font-size: 12px;
			line-height: 17px;
			text-align: center;
			opacity: 0.5;
		}

		.price-offers {
			margin-bottom: 30px;

			display: grid;
			grid-template-columns: 34.4px auto;
			grid-auto-rows: auto;
			gap: 20px 9.6px;

			.lock-icon-wrapper {
				margin-top: 7px;
				width: 34.4px;
				height: 33.6px;

				display: flex;
				justify-content: center;
				align-items: center;

				border-radius: 50%;

				background: var(--color--red-orange-gradient-horizontal);

				.lock-icon {
					width: 100%;
					height: 100%;
				}

				.price-offer {
					font-weight: 500;
					font-size: 15px;
					line-height: 140%;
					color: var(--color--black);

					opacity: 0.9;
				}
			}
		}

		.button {
			margin-bottom: 15px;

			width: 100%;
			max-width: 335px;
		}
	}
}

.offer {
	margin-bottom: 60px;

	display: flex;
	flex-direction: column;
	align-items: center;

	&__title {
		padding: 0 20px;
		margin-bottom: 15px;

		font-weight: 900;
		font-size: 32px;
		line-height: 35px;

		text-align: center;

		text-shadow: 0px 2px 1px rgba(38, 22, 133, 0.22), 0px 0.8px 0px #7a17a8;
		-webkit-text-stroke: 1px #7a17a8;
	}

	&__image {
		width: 100%;
	}

	&-options {
		margin-bottom: 40px;

		width: calc(100% - 42px);

		font-family: 'Nunito Sans', sans-serif;

		display: grid;
		align-items: center;
		grid-template-columns: 1fr;
		grid-auto-rows: 86px;
		gap: 19px;

		.option {
			position: relative;
			padding: 16px 10px 16px 24px;

			background: #343449;
			border-radius: 13px;

			display: grid;
			grid-template-columns: 1fr 119px;
			gap: 10px;

			transition: all 0.35s;

			&-wrapper {
				padding: 2px;

				background: linear-gradient(
					180deg,
					rgba(255, 68, 149, 0.4) 0%,
					rgba(206, 7, 114, 0.4) 100%
				);

				border-radius: 13px;

				&--selected {
					.option {
						mix-blend-mode: hard-light;
						border: 1px solid #ff9983;
						box-shadow: inset -0.13px 0.26px 0.13px rgba(255, 255, 255, 0.5);
					}
				}
			}

			&__bestler-mark {
				position: absolute;
				top: -11px;
				left: calc(50% - 41.5px);

				padding: 3px 8px;

				width: 83px;
				height: 21px;

				font-weight: 600;
				font-size: 11px;
				line-height: 137.3%;
				text-align: center;

				display: flex;
				justify-content: center;
				align-items: center;

				background: #f352b2;
				border-radius: 6px;
			}

			&-info {
				display: flex;
				flex-direction: column;
				justify-content: center;
				&__trail {
					margin: 0;
					font-weight: 800;
					font-size: 24px;
					line-height: 30px;
					text-transform: uppercase;
				}

				&__price {
					margin: 0;
					font-weight: 600;
					font-size: 13px;
					line-height: 137.3%;
				}
			}

			&__price-image {
				width: 119px;
				height: 54px;
			}
		}
	}

	&__option-description {
		margin-top: 20px;
		padding: 0 37px;

		font-size: 12px;
		line-height: 16px;

		text-align: center;
		

		color: #d6d6d6;

		a {
			text-decoration: underline;
			color: #d6d6d6;
		}

		&-bold{
			color: white; 
			font-weight: 700;
		}
	}
}
