.email {
	width: 100%;
	max-width: 480px;
	height: 100%;
	padding-top: 69px;
	display: flex;
	flex-direction: column;

	background: url(./img/bg.png) no-repeat center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;

	font-family: 'Nunito', sans-serif;
	color: #fff;

	.email__logo {
		width: 100%;
	}
}
