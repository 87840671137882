.App {
	width: 100%;
	max-width: 480px;
	position: relative;
	display: flex;
	height: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	background-image: url('./backgrounds/background1.png');
	background-size: cover;
	background-position: center;

	overflow: hidden;
}
.background2 {
	background-image: url('./backgrounds/background2.png');
}
.background3 {
	background-image: url('./backgrounds/background3.png');
}
.background4 {
	background-image: url('./backgrounds/background4.png');
}
.abs-under {
	position: absolute;
	display: none;
	z-index: -999999;
}
#root {
	height: 100%;
}
a:hover {
	text-decoration: none;
}
body {
	height: 100vh;
}
