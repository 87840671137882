.questioncomponent {
	width: 100%;
	max-width: 480px;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	height: 100%;
	background-size: cover;
	z-index: 0;
	background-position: center;
}
.background1 {
	background-image: url('../../backgrounds/background1.png');
}
.background2 {
	background-image: url('../../backgrounds/background2.png');
}
.background3 {
	background-image: url('../../backgrounds/background3.png');
}
.background4 {
	background-image: url('../../backgrounds/background4.png');
}
.background5 {
	background-image: url('../../backgrounds/background5.png');
}
.background6 {
	background-image: url('../../backgrounds/background6.png');
}
.background7 {
	background-image: url('../../backgrounds/background7.png');
}
.background8 {
	background-image: url('../../backgrounds/background8.png');
}
.background9 {
	background-image: url('../../backgrounds/background9.png');
}
.background10 {
	background-image: url('../../backgrounds/background10.png');
}
.background11 {
	background-image: url('../../backgrounds/background11.png');
}
.background12 {
	background-image: url('../../backgrounds/background12.png');
}
.background13 {
	background-image: url('../../backgrounds/background13.png');
}
.background14 {
	background-image: url('../../backgrounds/background14.png');
}
.background15 {
	background-image: url('../../backgrounds/background15.png');
}
.background16 {
	background-image: url('../../backgrounds/background16.png');
}
