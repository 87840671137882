.marriage {
	position: relative;
	width: 100%;
	.marriage__message {
		width: 100%;
		.message__field {
			width: 100%;
			position: relative;
			margin-top: 45%;
			display: flex;
			justify-content: flex-end;
			height: 100px;
			.message__text_question {
				margin: 0;
				width: 40%;
				right: 14.5%;
				margin-top: 18px;
				text-align: center;
				position: absolute;
				font-family: Nunito Sans;
				font-style: normal;
				font-weight: 900;
				font-size: 13px;
				line-height: 25px;
				color: white;
				/* or 192% */
				text-transform: uppercase;
			}
			.message__image_question {
				height: 90px;
				margin-right: 3px;
				margin-left: 10%;
			}
		}
		.answer__field {
			width: 100%;
			display: flex;
			text-decoration: none;
			flex-direction: row;
			justify-content: flex-end;
			margin-top: 2px;
			.answer1 {
				width: 305px;
				margin: 5px;
				margin-right: 5px;
				margin-top: 2px;
				padding: 10px;
				font-size: 16px;
				font-family: Nunito Sans;
				font-style: normal;
				font-weight: bold;
				font-size: 14px;
				color: white;
				line-height: 16px;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			.answer__active {
				background: linear-gradient(
					90deg,
					#ff8a23 0%,
					#f86f5f 21.87%,
					#f23484 52.6%,
					#eb2454 100%
				);
				box-shadow: inset 0px 1px 0px #ff4599, inset 0px -2px 0px #b72552;
				border-radius: 14px;
			}
			.answer__none {
				background: #db4378;
				box-shadow: inset 0px 1px 0px #ff4599, inset 0px -2px 0px #b72552;
				border-radius: 14px;
			}
		}
	}
}
