.authorize-form {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	&__description {
		margin: 15px 0 0;

		text-align: center;
		font-size: 16px;
		line-height: 22px;

		font-family: 'Nunito Sans', sans-serif;

		opacity: 0.9;
	}

	&__link {
		margin-top: 30px;

		font-weight: normal;
		font-size: 14px;
		line-height: 15px;
		letter-spacing: 0.05em;
		text-transform: capitalize;

		color: #fff;

		opacity: 0.8;

		text-decoration: none;

		&:active {
			color: #f9319c;
		}
	}
	&__link:hover {
		color: #fff;
	}
}

.authorize-form-button-container{
	z-index: 2;
	position: relative;

	max-width: 335px;
	width: 100%;

	display: flex;
	justify-content: center;

	.button{
		width: 100%;
	}
}
