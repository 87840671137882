.header {
	padding: 10px 20px 10px;
	margin: 0 auto;

	display: flex;
	align-items: center;
	justify-content: space-between;

	&-container {
		z-index: 4;

		width: 100%;
		position: fixed;
		top: 0;
		left: 0;

		background: #090e18;

		transition: background 0.35s;

		&--opened-burger {
			background: #090e18 !important;
		}
	}

	.back-button {
		width: 50px;
		height: 49px;
		display: flex;
		align-items: center;

		user-select: none;

		&__text {
			margin: 0;
			font-family: 'Nunito', sans-serif;

			font-weight: 900;
			font-size: 17px;
			line-height: 30px;

			text-shadow: 0px 2px 1px rgba(38, 22, 133, 0.22), 0px 0.8px 0px #7a17a8;

			color: var(--color--white);
		}

		@media screen and (min-width: 1024px) {
			cursor: pointer;
		}
	}

	.header-logo {
		width: 123px;
		height: 35px;
	}

	.burger-icon {
		width: 50px;
		height: 50px;

		border-radius: 50%;

		user-select: none;

		display: flex;
		justify-content: center;
		align-items: center;

		@media screen and (min-width: 1024px) {
			cursor: pointer;
		}

		&--pink {
			background: linear-gradient(180deg, #df2f64 13.02%, #b3186b 100%);
			box-shadow: inset 0px 2px 1px #f04294,
				inset 0px -3px 2px rgba(229, 63, 159, 0.35);
		}
	}
}
