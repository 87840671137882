.sale-header {
	z-index: 10;

	position: fixed;
	top: 0;
	left: 0;
	width: 100%;

	padding: 9px 20px;

	width: 100%;

	display: flex;
	justify-content: space-between;
	align-items: center;

	background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.37) 100%);
	backdrop-filter: blur(4px);

	&__logo {
		width: 119.54px;
		height: 32.68px;
	}

	.button {
		max-width: 167px;
		padding: 16px 16px 15px;

		@media screen and (max-width: 343px) {
			padding: 16px 10px 15px;

			max-width: 163px;

			font-size: 16px;
		}
	}
}
