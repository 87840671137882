.question__control__custom {
	display: flex;
}
.carousel__book {
	width: 100%;
	margin-top: 5%;
	height: 400px;
	text-align: center;
}
.carousel-inner__books {
	height: 400px;
	text-align: center;
	text-align: center;
}
.carousel-item__book {
	text-align: center;
	position: relative;
	height: 400px;
	width: 100%;
	.book__text {
		position: absolute;
		padding: 10px;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		width: 248px;
		height: 400px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.book-text__header {
			font-size: 23px;
			margin: 0px;
		}
		.text__bottom {
			font-size: 16px;
			margin: 0px;
			.author {
				text-align: end;
				font-size: 16px;
				margin: 0px;
			}
			.description {
				font-size: 16px;
				text-align: left;
				margin: 0px;
			}
		}
	}
}
.carousel__books {
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;

	position: absolute;
}
.button__position {
	position: relative;
	height: 12%;
	width: 100%;
	.control__button {
		margin: 0px;
		margin-bottom: 5%;
	}
}
.white {
	color: white;
}
.black {
	color: black;
}
