.discount {
	margin-top: 69px;
	z-index: 2;

	position: relative;

	width: 100%;
	max-width: 480px;

	display: flex;
	flex-direction: column;
	align-items: center;

	font-family: 'Nunito', sans-serif;
	color: #ffffff;
	background: #03001d;

	.discount__welcome {
		margin: 0 auto;

		width: 100%;
	}

	.dark-room {
		position: relative;

		margin: 0 auto 10px;

		width: 100%;

		&__image {
			width: 100%;
		}

		.button-wrapper {
			position: absolute;
			left: 0;

			width: 100%;

			display: flex;
			justify-content: center;

			.button {
				max-width: 295px;
			}

			@media screen and (min-width: 300px) {
				bottom: 14px;
			}

			@media screen and (min-width: 350px) {
				bottom: 22px;
			}

			@media screen and (min-width: 375px) {
				bottom: 32px;
			}

			@media screen and (min-width: 400px) {
				bottom: 40px;
			}

			@media screen and (min-width: 430px) {
				bottom: 50px;
			}

			@media screen and (min-width: 460px) {
				bottom: 60px;
			}
		}
	}

	.discount-money-back {
		margin-bottom: 30px;

		display: flex;
		flex-direction: column;
		align-items: center;

		text-align: center;

		&__image {
			margin-bottom: 25px;

			width: 100%;
			max-width: 375px;
		}

		&__title {
			margin: 0 30px 11px;

			font-weight: 900;
			font-size: 28px;
			line-height: 34px;
			-webkit-text-stroke: 1px #7a17a8;
			text-shadow: 0px 2px 1px rgba(38, 22, 133, 0.22), 0px 0.8px 0px #7a17a8;
		}

		&__description {
			margin: 0 23px 20px;

			font-size: 14px;
			line-height: 21px;

			text-align: center;
			letter-spacing: 0.25px;

			opacity: 0.6;
		}
	}
}
