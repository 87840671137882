.email-form {
	height: 100vh;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 125px;

	&__title {
		margin: 0 auto 15px;

		width: 100%;
		max-width: calc(100% - 102px);

		font-weight: 900;
		font-size: 35px;
		line-height: 40px;

		text-align: center;

		-webkit-text-stroke: 1px #7a17a8;
		text-shadow: 0px 2px 1px rgba(38, 22, 133, 0.22), 0px 0.8px 0px #7a17a8;
	}

	&__description {
		margin-bottom: 40px;
		padding: 0 47px 0;

		font-weight: 600;
		font-size: 18px;
		line-height: 22px;
		text-align: center;
	}

	&__input {
		margin: 0 auto 20px;
		padding: 15px 20px 16px;

		width: calc(100% - 40px);
		max-width: 335px;
		max-height: 50px;

		font-weight: normal;
		font-size: 16px;
		line-height: 22px;
		color: white;
		font-family: 'Nunito Sans', sans-serif;

		background: #460F38;
		border: 2px solid rgba(253,66,147, 0.3);
		

		border-radius: 13px; 

		&::placeholder{
			color: white;
			opacity: 0.6;
		}

		&:focus {
			border: 2px solid rgba(253,66,147, 1);
			outline: none;
		}

		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:active {
			-webkit-box-shadow: 0 0 0 30px white inset !important;
			box-shadow: 0 0 0 30px white inset !important;
		}
	}

	.email-form-button-container {
		z-index: 2;

		position: relative;

		width: calc(100% - 40px);
		max-width: 335px;

		display: flex;
		justify-content: center;

		.disabled {
			position: absolute;
			top: 0;

			width: 100%;
			height: 100%;

			background: rgba(0, 0, 0, 0.6);
			border-radius: 25px;
			z-index: 4;
			
		}

		.button {
			width: 100%;
		}
	}
}
