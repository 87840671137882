body {
	margin: 0px;
	padding: 0px;
}
#root {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	
}
button {
	background: none;
	border: none;
}
