.background-changer {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 50vh;
	.background-changer__arrow {
		margin: 10px;
		cursor: pointer;
	}
}
.background-slider {
	position: absolute;
	z-index: -1;
	width: 100%;
}
.characters-bottom {
	position: absolute;
	height: 80%;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: hidden;
	.characters-bottom__mainhero {
		position: absolute;
		height: 95%;
		left: -60px;
		bottom: -80px;
	}

	.characters-bottom__loveinterest {
		position: absolute;
		height: 95%;
		right: -60px;
		transform: scaleX(-1);
		bottom: -80px;
	}
}
.background-block-image {
	height: 100vh;
}
.carousel_itemcustom {
	position: relative;
}
.preloader-background {
	position: absolute;
	z-index: -1;
	margin-top: 104%;
	margin-left: 45%;
}
.carousel-control-prev {
	opacity: 1;
}
.carousel-control-next {
	opacity: 1;
}
