.home {
	margin-top: 45vh;
	font-size: 30px;
	padding: 20px;
	background-color: white;
	border-radius: 20px;
	text-decoration: none;
}
.home:hover {
	color: darkblue;
	text-decoration: none;
}
.home__header {
	position: fixed;
	max-width: 480px;
	z-index: 11;
	width: 100%;
	height: 69px;
	background: #090e18;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.12);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	.home__logo {
		height: 40px;
		margin: 10px;
		margin-top: 15px;
	}
	.home-header__rightside {
		.home-header__button {
			margin-right: 20px;
			padding: 7px;
			width: 149px;
			height: 42px;
			padding-left: 15px;
			padding-right: 15px;
			color: white;
			border-radius: 30px;
			border: 2px solid #dd4f87;
			outline: none;
			background: linear-gradient(180deg, #db5186 0%, #c6236a 100%);
			font-family: Helvetica;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 130%;
			color: #ffffff;
		}
		.home-header__icon {
			margin: 25px;
			color: white;
		}
	}
}
.home__body {
	background: #0e0e0e;
	width: 100%;
	padding: 15px;
	padding-right: 0px;
}
.body-header__text {
	font-family: Helvetica;
	color: #c4c4c4;
	font-style: normal;
	margin-top: 24px;
	margin-bottom: 56px;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
}
.body__feedback {
	width: 100%;
	background: #0e0e0e;
	height: 30px;
	margin-bottom: 15px;
	.feedback__arrows {
		width: 100%;
		height: 100%;
		margin-left: 67.5%;

		.feedback-arrows__arrow {
			color: #c6236a;
			margin: 10px;
		}
	}
}
.feedback__slider {
	width: auto;
	height: auto;
	background: #0e0e0e;
	display: flex;
	flex-direction: row;
	overflow-x: scroll;
	margin-bottom: 40px;
}
.home__footer {
	height: 180px;
	padding: 20px;
	padding-top: 24px;
	background: #0e0e0e;
	width: 100%;
	border-top: 1px solid #6a6a6a;
	.footer__grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		.footer__section {
			margin-bottom: 40px;
			width: 200px;
			.section__h {
				font-family: Helvetica;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				margin-bottom: 15px;
				line-height: 150%;
				color: #ffffff;
			}
			.section__p {
				font-family: Helvetica;
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 150%;
				margin: 5px;
				margin-left: 0px;
				color: #ffffff;
			}
		}
	}

	.footer__text {
		font-family: Helvetica;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		line-height: 120%;
		color: #ffffff;
	}
}
.custom-caption {
	text-align: center;
	display: flex;
	justify-content: center;
}
.home__slider {
	width: 100%;
	margin-top: 58px;
}
.control__button {
	height: 42px;
	width: 300px;
	border: 2px solid #d34e7e;
	border-radius: 21px;
	background: linear-gradient(#db5186, #c6236a);
	z-index: 0;
	font-family: 'Nunito Sans', sans-serif;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	text-decoration: none;
	position: absolute;
	bottom: 0;
	margin-bottom: 70px;
}
.writing__text {
	margin-top: 100%;
	margin-left: 24%;
	position: absolute;
	margin-bottom: 2px;
	width: 70%;
	font-family: Nunito;
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 21px;
	letter-spacing: -0.05px;
	color: #303052;
}
.modal__button {
	height: 42px;
	width: 216px;
	border: 2px solid #d34e7e;
	border-radius: 21px;
	background: linear-gradient(#db5186, #c6236a);
	font-family: 'Nunito Sans', sans-serif;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	text-decoration: none;
}
.modal__button-disabled {
	height: 42px;
	width: 216px;
	border: 2px solid #efb6cd;
	border-radius: 21px;
	pointer-events: none;
	background: #e9a0be;
	font-family: 'Nunito Sans', sans-serif;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	text-decoration: none;
}
.home__slider {
	div {
		a {
			display: none;
		}
	}
}
.modal__body {
	div {
		display: flex;
		align-items: center;
		p {
			margin: 0;
			margin-left: 10px;
		}
	}
}
.carousel-indicators .active {
	background-color: #cd3374;
	border: 1px solid #cd3374;
}
.carousel-indicators li {
	border-radius: 1.5px;
	border: 1px solid white;
	height: 1px;
	width: 20px;
}
.home__menu {
	width: 350px;
	height: 100vh;
	position: fixed;
	right: 0;
	background: white;
	z-index: 1000;
	padding: 25px;
	.menu__header {
		display: flex;
		justify-content: flex-end;
	}
	.menu__body {
		margin-top: 79px;
		margin-bottom: 40px;
		.menu-body__text {
			font-family: Helvetica;
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 130%;
			display: flex;
			align-items: center;
			color: #282828;
		}
	}
	.menu__footer {
		margin-top: 40px;
		.menu-footer__text {
			font-family: Helvetica;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 17px;
			display: flex;
			align-items: center;

			color: #282828;
		}
	}
}

.none {
	display: none;
}
.animation {
	animation-name: scaling2;
	animation-duration: 4s;
	animation-iteration-count: infinite;
}
.react-multi-carousel-list {
	margin-bottom: 40px;
}
.slider__img {
	width: 100%;
}
.modal-content {
	border-radius: 15px;
}
.modal__text {
	font-family: Helvetica;
	font-weight: 500;
	font-size: 16px;
	line-height: 120%;
	color: #3d3c3c;
}
.home__spinner {
	margin-top: 40vh;
	z-index: 9999;
}

@keyframes scaling2 {
	0% {
		transform: scale(1);
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
		transform: scale(1);
	}
}

.spinner__background {
	background: #0e0e0e;
	width: 100%;
	height: 800px;
}
