.background-changer__title {
	color: black;
	font-weight: 500;
	font-size: 14px;
	width: 90%;
	text-align: center;
	padding: 15px;
	background: white;
	margin-top: 40px;
	border-radius: 20px;
}
.background-changer {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	padding-top: 40%;
	height: 50vh;
	.background-changer__arrow {
		margin: 10px;
		cursor: pointer;
	}
}
