.namebox {
	position: relative;
	margin-top: 20vh;
	padding: 12px 24px 12px 24px;
	width: 80%;
	background: white;
	height: 220px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	border-radius: 20px;
	border: 1px solid rgba(74, 82, 92, 0.6);
	.namebox__text {
		text-align: center;
		font-family: 'Nunito Sans Bold', sans-serif;
		font-size: 18px;
		line-height: 20px;
		color: #303052;
		font-weight: bold;
	}
	.namebox__input {
		background: rgba(48, 48, 82, 0.07);
		width: 248px;
		height: 48px;
		font-family: 'Nunito Sans Bold Italic', sans-serif;
		text-align: center;
		border: none;
		border-radius: 12px;
		opacity: 0.6;
	}
}
.question__control-name {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: space-around;
	justify-content: center;
	.control__button-name {
		height: 42px;
		width: 216px;
		border-radius: 21px;
		background: linear-gradient(#db5186, #c6236a);
		z-index: 0;
		font-family: 'Nunito Sans', sans-serif;
		display: flex;
		justify-content: center;
		align-items: center;
		color: white;
		text-decoration: none;
	}
}
.control__button-name-disabled {
	pointer-events: none;
}
