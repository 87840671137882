.custom-input {
	padding: 15px 29px 16px;
	margin: 30px 0 15px;

	width: 100%;
	max-height: 50px;

	font-weight: 600;
	font-size: 16px;
	line-height: 23px;

	color: #ffffff !important;
	background: transparent;
	border: 1px solid #615968;
	border-radius: 12px;

	font-family: Nunito Sans;

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		-webkit-box-shadow: rgba(255, 255, 255, 1) inset !important;
		box-shadow: rgba(255, 255, 255, 1) inset !important;
	}
	&:-webkit-autofill {
		-webkit-text-fill-color: #ffffff !important;
		-webkit-background-clip: text;
		background-clip: text;
	}
	&--valid {
		border: 1px solid #fd339a;
	}
	&::placeholder {
		color: rgba(255, 255, 255, 0.7);
	}
}

.custom-input:focus {
	outline: none;
}
