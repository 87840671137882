.email-footer {
	margin-top: 12px;
	padding: 0 31px;

	max-width: 375px;

	text-align: center;

	.footer-text {
		width: 100%;
		font-size: 10px;
		line-height: 130%;

		text-align: center;
		letter-spacing: 0.25px;

		color: var(--color--gray-light);

		.text-link {
			background: rgb(232, 0, 95);
			background-clip: text;
			-webkit-text-fill-color: transparent;
		}

		&:nth-of-type(2) {
			position: relative;
			top: -10px;
		}
	}
	&-privacies {
		margin-bottom: 40px;
		margin-top: 28px;
		font-family: 'Nunito Sans', sans-serif;

		display: grid;
		grid-template-columns: 285px;
		grid-auto-rows: 39px;
		gap: 17px;

		.privacy {
			display: grid;
			grid-template-columns: 15px 1fr;
			gap: 10px;

			&-checkbox {
				width: 15px;
				height: 15px;

				display: flex;
				justify-content: center;
				align-items: center;

				border: 1px solid #fd4293;
				border-radius: 3px;
				background: #460f38;

				&__image {
					opacity: 0;
					height: 4.5px;
					width: 7px;
					transition: opacity 0.35s;
				}

				&--checked {
					.privacy-checkbox__image {
						opacity: 1;
					}
				}
			}

			&-text {
				font-size: 10px;
				line-height: 130%;

				letter-spacing: 0.25px;

				color: #808897;

				&__link {
					background: rgb(232, 0, 95);
					background-clip: text;
					-webkit-text-fill-color: transparent;
					text-decoration: none;
				}
			}
		}
	}
}
