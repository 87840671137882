.processing-container {
	width: 100%;
	max-width: 480px;
	height: 100vh;

	display: flex;
	justify-content: center;
	align-items: center;

	background: #280b2c;

	font-family: 'Nunito', sans-serif;
	color: #fff;

	.processing {
		width: 100%;

		display: flex;
		flex-direction: column;

		&__loading {
			display: flex;
			justify-content: center;
		}
		&-info {
			position: relative;
			top: -111px;

			display: flex;
			flex-direction: column;
			align-items: center;

			&__progress {
				margin-bottom: 15px;

				font-weight: bold;
				font-size: 14px;
				line-height: 150%;

				text-align: center;
			}

			&__label {
				padding: 0 16px;

				width: 100%;

				font-weight: 600;
				font-size: 17px;
				line-height: 21px;
				text-align: center;
			}

			@media screen and (min-width: 350px) {
				top: -137px;
			}

			@media screen and (min-width: 400px) {
				top: -152px;
			}

			@media screen and (min-width: 450px) {
				top: -170px;
			}
		}

		&--show {
			animation-name: fade-in;
			animation-duration: 700ms;
			animation-iteration-count: 1;
			animation-fill-mode: forwards;
		}

		&--hide {
			animation-name: fade-out;
			animation-duration: 350ms;
			animation-iteration-count: 1;
			animation-fill-mode: forwards;
		}
	}
}
