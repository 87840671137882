.sale-reviews {
	margin-bottom: 60.21px;

	display: flex;
	flex-direction: column;
	align-items: center;

	background: url(./img/customers-bg.png) no-repeat center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;

	text-align: center;

	&__title {
		margin: 0 20px 30px;

		max-width: 100%;

		font-weight: 900;
		font-size: 30px;
		line-height: 34px;
		-webkit-text-stroke: 1px #7a17a8;
		text-shadow: 0px 2px 1px rgba(38, 22, 133, 0.22), 0px 0.8px 0px #7a17a8;

		@media screen and (max-width: 375px) {
			max-width: 225px;
		}
	}

	&__image {
		padding: 0 20px;
		margin-bottom: 2px;

		width: 100%;
		max-width: 375px;
	}
}
