.message__answer_text {
	margin: 0;
	width: 40%;
	text-align: center;
	position: absolute;
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 900;
	font-size: 13px;
	line-height: 25px;
	color: white;
	/* or 192% */
	text-transform: uppercase;
}
.message__answer_image1 {
	height: 129px;
	width: 296px;
	margin-right: 10%;
}

.message__answer_image2 {
	height: 100px;
	width: 274px;
	margin-right: 11%;
}
.message__answer_image3 {
	height: 129px;
	width: 296px;
	margin-right: 10%;
}

.message__answer_image4 {
	height: 150px;
	width: 296px;
	margin-right: 10%;
}

.message__answer_image5 {
	height: 170px;
	width: 296px;
	margin-right: 10%;
}

.text1 {
	margin-right: 135px;
	margin-top: 18px;
}
.text2 {
	margin-right: 128px;
	margin-top: 15.5px;
}
.text3 {
	margin-right: 135px;
	margin-top: 18px;
}
.text4 {
	margin-right: 135px;
	margin-top: 17px;
}
.text5 {
	margin-right: 135px;
	margin-top: 18px;
}
