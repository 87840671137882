@keyframes fade-in {
	0% {
		opacity: 0;
		pointer-events: none;
	}
	100% {
		opacity: 1;
		pointer-events: auto;
	}
}

@keyframes fade-out {
	0% {
		opacity: 1;
		pointer-events: auto;
	}
	100% {
		opacity: 0;
		pointer-events: none;
	}
}

@keyframes move-text {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes tooltip-bouncing {
	0% {
		top: -5px;
	}

	50% {
		top: 5px;
	}

	100% {
		top: -5px;
	}
}

@keyframes show-left-hero {
	0% {
		left: -300px;
	}
	100% {
		left: -125px;
	}
}

@keyframes show-right-hero {
	0% {
		right: -300px;
	}
	100% {
		right: -125px;
	}
}

@keyframes hide-left-hero {
	0% {
		left: -125px;
	}

	100% {
		left: -300px;
	}
}

@keyframes hide-right-hero {
	0% {
		right: -125px;
	}

	100% {
		right: -300px;
	}
}

@keyframes fade-in-dialog {
	0% {
		display: none;
	}
	100% {
		display: block;
	}
}

@keyframes slidein {
	from {
		margin-left: 100%;
		width: 300%;
	}

	to {
		margin-left: 0%;
		width: 100%;
	}
}
