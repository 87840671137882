.question__field {
	height: 13%;
	width: 90%;
	background: rgba(#ffffff, 0.8);
	margin-top: 5%;
	min-height: 80px;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	.question__header {
		color: black;
		position: relative;
		opacity: 1;
		.header__text {
			text-align: center;
			position: absolute;
			left: 0px;
			right: 0px;
			font-size: 15px;
			margin: 0px;
			line-height: 24px;
			font-weight: bold;
			color: white;
			font-family: 'Nunito Sans', sans-serif;
			text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		}
		.header__img {
			position: absolute;
			height: 25px;
			margin: auto;
			z-index: 0;
			left: 0px;
			top: -20;
			right: 0px;
		}
	}
	.question__text {
		color: #141a3d;
		opacity: 1;
		font-family: 'Nunito Sans Bold', sans-serif;
		text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		font-size: 20px;
		line-height: 24px;
		text-align: center;
		font-weight: 700;
		height: 100%;
		margin: 0px;
		margin-top: 18px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
