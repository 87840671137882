.carousel__custom {
	width: 100%;
	position: absolute;
	bottom: 0px;
	height: 85%;
	.carousel-inner__custom {
		text-align: center;
		height: 100%;
		.carousel-item__custom {
			text-align: center;
			transition: transform 0.4s;
			height: 100%;
			.carousel-block__custom {
				position: relative;
				width: 100%;
				height: 100%;
				.carousel-image {
					position: absolute;
					bottom: -50px;
					height: 110%;
					margin-left: auto;
					margin-right: auto;
					left: 0;
					right: 0;
				}
			}
		}
	}
}
.preloader {
	margin-top: 87%;
}
.carousel-control__custom {
	opacity: 1;
}
.arrow {
	height: 56px;
	animation-name: scaling;
	animation-duration: 4s;
	animation-iteration-count: infinite;
}

@keyframes scaling {
	0% {
		transform: scale(1);
		transform: scale(1);
	}
	50% {
		transform: scale(1.2);
		transform: scale(1.2);
	}
	100% {
		transform: scale(1);
		transform: scale(1);
	}
}
.image-none {
	opacity: 0;
}
.pointer-disabled {
	pointer-events: none;
}
