.error {
	height: 100vh;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	text-align: center;

	font-family: 'Nunito', sans-serif;
	color: #fff;

	&__image {
		padding: 0 48px;
		margin-bottom: 31px;

		width: 100%;
		max-width: 375px;
	}

	&__title {
		margin-bottom: 15px;

		font-weight: 800;
		font-size: 40px;
		line-height: 120%;
	}

	&__description {
		margin-bottom: 30px;

		font-size: 15px;
		line-height: 25px;
		letter-spacing: 0.25px;
	}

	.button {
		margin: 0 27px;

		width: calc(100% - 54px);
		max-width: 321px;
	}
}
