.choice {
	z-index: 2;

	width: 298px;

	margin: 3px 0 0 10%;
	padding: 15px 14px;

	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.03px;

	color: #ffffff;

	background: var(--color--linear-pink);

	border-radius: 25px;

	@media screen and (max-width: 350px) {
		width: 260px;
	}
}
