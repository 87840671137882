.sale-screen-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	padding-top: 150vh;
	transition: 1.1s ease-in-out;
}

.sale-screen-modal {
	position: relative;
	display: block;
	margin-top: 16px;
	border-radius: 8px 8px 0px 0px;
	background: #ffffff;
	max-width: 475px;
	width: 475px;
	&__header {
		width: 100%;
		position: fixed;
		border-radius: 8px 8px 0px 0px;
		max-width: inherit;
		padding: 17px 0;
		background-color: #fff;
		z-index: 999;
		.modal__btn {
			display: flex;
			flex-direction: column;
			margin-bottom: 22px;
			&-line {
				width: 40px;
				height: 2.2px;
				background: #b2b2b2;
				opacity: 0.5;
				border-radius: 1.5px;
				margin: 0 auto;
			}
			&-close {
				width: 16px;
				height: 16px;
				padding: 1px;
				margin-right: 20px;
				align-self: flex-end;
			}
		}

		.modal__cards {
			margin: 0 32px;
			.modal {
				&__title {
					margin: 0 0 16px 0;
					padding: 0 20px 12px 20px;
					font-weight: 600;
					font-size: 23px;
					line-height: 120%;
					text-align: center;
					color: #191919;
				}
				&__cards {
					margin-bottom: 30px;
					font-size: 16px;
					line-height: 1;
				}
				&__card {
					box-sizing: border-box;
					height: 59px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-weight: 500;
					color: #808897;
					background: #f7f8fb;
					border: 1px solid #e1e1e1;
					border-radius: 12px;
					padding: 20px;
					margin-bottom: 8px;
					div {
						display: flex;
						align-items: center;
						.paypal-image {
							width: 88px;
						}
						.credits-image {
							width: 123px;
							margin-bottom: -2px;
						}
						span {
							display: inline-block;
							margin-right: 8px;
						}
					}
					&-done {
						width: 20px;
						height: 20px;
						background: #ffffff;
						border: 1.5px solid #e7e8ea;
						box-sizing: border-box;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
				&__card:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&__content {
		margin: 290px 32px 0 32px;
		line-height: 24px;
		color: #808897;
		display: flex;
		flex-direction: column;
		font-weight: 500;
		.modal {
			&__annual-plan {
				display: flex;
				justify-content: space-between;
				margin-bottom: 6.5px;
				span:last-child {
					font-weight: 600;
					font-size: 18px;
				}
			}
			&__discount {
				display: flex;
				justify-content: space-between;
				margin-bottom: 22px;
				span:last-child {
					font-size: 24px;
					color: #4380ff;
					font-weight: 600;
				}
			}
			&__total {
				display: flex;
				justify-content: space-between;
				font-size: 24px;
				font-weight: 600;
				color: #fa3269;
			}
			&__privacy {
				text-align: center;
				font-weight: 400;
				color: #000000;
				margin: 16px 10px;
			}
		}
	}
}

.show-modal {
	transition: padding 0.7s ease-in-out, background 1s ease-in-out;
	background: rgba(0, 0, 0, 0.4);
	padding-top: 55px;
	z-index: 999;
}

.hide-modal {
	transition: padding 0.7s ease-in-out, z-index 1.5s ease-in-out,
		background 1s ease-in-out;
	background: rgba(0, 0, 0, 0);
	padding-top: 150vh;
	z-index: -999;
}

.show-payment-type {
	// transition: scale 1s linear;
	display: none;
}

.active-paymant {
	border: 2px solid #fa3269 !important;
	color: #fa3269 !important;
	.modal__card-done {
		background: #fa3269 !important;
	}
}

@media (max-height: 800px) {
	.sale-screen-modal {
		overflow-y: auto;
	}
}

@media (max-width: 360px) {
	.card__credit {
		div {
			span {
				max-width: 50px;
			}
		}
	}
	.sale-screen-modal {
		&__wrapper {
			margin: 0 15px;
		}
	}
}

@media (max-width: 310px) {
	.sale-screen-modal {
		&__wrapper {
			margin: 0 0px;
		}
	}
}

#solid-payment-form-container {
	min-height: 300px;
	height: 300px;
	width: 100%;
}

#solid-payment-form-iframe {
	width: 100%;
	height: 300px;
	margin-top: 25px;
	border: none;
}
