.account {
	font-family: 'Nunito', sans-serif;

	padding: 101px 27px;

	width: 100%;
	height: 100vh;

	display: flex;
	flex-direction: column;
	align-items: center;

	justify-content: center;
	color: var(--color--white);

	background: radial-gradient(
		100% 100% at 50% 100%,
		#43144f 0%,
		#351544 53.12%,
		#0e0a26 100%
	);

	transition: all 0.25s;

	input {
		margin-bottom: 15px;
	}

	&__header {
		text-shadow: 0px 2px 1px rgba(38, 22, 133, 0.22), 0px 0.8px 0px #7a17a8;
		-webkit-text-stroke: 1px #7a17a8;

		font-weight: 900;
		font-size: 28px;
		line-height: 40px;
	}
}
