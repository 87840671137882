.header {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 10%;
	z-index: 100;
	.header__logo {
		margin: 15px;
		margin-top: 20px;
		height: 40px;
	}
	.header__icon {
		margin: 15px;
		margin-right: 30px;
		font-size: 25px;
		color: white;
		cursor: pointer;
	}
}
.modalwindow {
	width: 100%;
	height: 100vh;
	background-color: darkblue;
	position: absolute;
	z-index: 10;
	.modal__header {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		.modal__headericon {
			color: white;
			font-size: 30px;
			margin: 20px;
			cursor: pointer;
		}
	}
}
.none {
	display: none;
}
.bg-custom {
	height: 5px;
}
.progress-bar {
	background-color: #ed5c9a;
	border-radius: 0px 2.5px 2.5px 0px;
}
.progressbar {
	border-radius: 0px 2.5px 2.5px 0px;
	width: 100%;
	height: 5px;
	background: none;
	transition: 0.5s;
}
