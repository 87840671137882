.warning-message {
	margin-bottom: 20px;

	max-width: 335px;

	display: grid;
	grid-template-columns: 40px 1fr;
	gap: 3px;

	background: #22262f;
	border: 1px solid rgba(12, 31, 61, 0.01);
	box-sizing: border-box;
	border-radius: 14px;

	padding: 12px 20px 17px;

	color: var(--color--white);

	&__image {
		width: 40px;
		height: 40px;
	}

	&__description {
		margin: 0;
		font-size: 15px;
		line-height: 22px;
	}
}
