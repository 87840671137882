.epilog__text {
	background: white;
	width: 80%;
	text-align: center;
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	color: #141a3d;
	padding: 10px;
	border-radius: 20px;
	margin-top: 25px;
}

.epilog__button {
	cursor: pointer;
	margin-top: 20px;
	width: 100%;
	margin-left: 20px;
}
.epilog__phone {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	align-items: center;
}
