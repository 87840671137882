.carousel__custom {
	width: 100%;
	position: absolute;
	bottom: 0px;
	.carousel-inner__custom {
		text-align: center;
		.carousel-item__custom {
			text-align: center;
			.carousel-block__custom {
				position: relative;
				width: 100%;
				.carousel-image-rotate {
					position: absolute;
					bottom: 0px;
					height: 110%;
					position: absolute;
					margin-left: auto;
					margin-right: auto;
					left: 0;
					right: 0;
					transform: scaleX(-1);
					bottom: -50px;
				}
			}
		}
	}
}
.carousel-control__custom {
	opacity: 1;
}
.rotate {
	transform: scaleX(-1);
}
