.success {
	background: #0e0e0e;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	.success__trans {
		margin-top: 10px;
		width: 60%;
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 120%;
		text-align: center;
		color: #ffffff;
	}
	.first-part {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 25px;
		letter-spacing: 0.25px;
		color: #ffffff;
	}
	.second-part {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 25px;
		letter-spacing: 0.25px;
		color: #ffffff;
	}
	.clipboard {
		padding: 11px 46px 11px 46px;
		background: white;
		opacity: 0.9;
		border: 1px solid #ffffff;
		border-radius: 10px;
		margin-top: 16px;
		.clipboard__text {
			margin: 0;
			font-family: 'Open Sans';
			font-style: normal;
			font-weight: bold;
			font-size: 16px;
			line-height: 25px;
			text-align: center;
			letter-spacing: 0.25px;
			color: linear-gradient(
				90deg,
				#272727 2.27%,
				#1f1526 23.14%,
				#291626 62.33%
			);
		}
	}
	label {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: normal;
		margin-top: 5px;
		font-size: 12px;
		line-height: 120%;
		text-align: center;
		color: #b1b4c0;
	}
	.instruction {
		margin-top: 29px;
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		text-align: center;
		text-decoration-line: underline;
		color: #db5186;
	}
	.appstore {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 50px;
		background: linear-gradient(180deg, #db5186 0%, #c6236a 100%);
		border: 2px solid rgba(255, 255, 255, 0.2);
		box-sizing: border-box;
		border-radius: 30px;
		width: 80%;
		height: 48px;
		color: white;
	}
	.appstore::before {
		content: '';
		background-image: url('./icon.png');
		background-size: 100% 100%;
		display: inline-block;
		height: 21px;
		width: 25px;
		margin-right: 5px;
	}
}
