.sale-footer {
	margin: 0 auto;

	width: 100%;
	max-width: 475px;

	display: flex;
	flex-direction: column;
	align-items: center;

	.money-back {
		position: relative;

		padding: 30px 19px;
		margin: 69px 0 72px;

		width: calc(100% - 78px);
		max-width: 375px;

		text-align: center;

		background: #121926;

		border: 2px solid #f352b2;
		box-sizing: border-box;
		border-radius: 16px;

		display: flex;
		flex-direction: column;
		align-items: center;

		&__title {
			margin-bottom: 15px;

			font-weight: 800;
			font-size: 28px;
			line-height: 30px;
		}

		&__description {
			font-weight: 500;
			font-size: 12px;
			line-height: 18px;

			a {
				text-decoration: underline;
				color: var(--color--white);
			}

			&-bold{
				font-weight: 700;
			}
		}

		&__ribbon {
			position: absolute;
			bottom: -70px;
			right: 12px;

			height: 90.2899398803711px;
			width: 65.25947570800781px;
		}
	}

	&__logos {
		width: 100%;
		max-width: 375px;
	}

	&-links {
		padding: 14.6px 25px 15px;

		width: 100%;

		display: flex;
		justify-content: space-between;
		align-items: center;

		.link {
			font-weight: 500;
			font-size: 12px;
			line-height: 19px;
			text-align: center;
			letter-spacing: 0.248359px;

			color: #fff;
			text-decoration: underline;

			opacity: 0.9;
		}

		@media screen and (min-width: 360px) {
			padding: 14.6px 45px 15px;
		}
	}

	&__adress {
		margin-bottom: 19px;

		width: calc(100% - 75px);
		max-width: 266px;

		font-size: 12px;
		line-height: 19px;
		text-align: center;
		letter-spacing: 0.248359px;

		color: #fff;

		opacity: 0.6;
	}
}
