* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	background: #03001d;
}

.none-media {
	opacity: 0;
	height: 1px;
	width: 1px;
	position: absolute;
	z-index: -99999;
	bottom: 0;
	display: none;
}
