.discount-offer {
	padding-top: 104px;
	max-width: 480px;
	width: 100%;
	height: 621px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	background: url('./img/offer.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	&__logo {
		position: absolute;
		top: 15px;
		left: 26px;

		width: 119.54px;
		height: 32.68px;
	}

	&-info {
		padding: 110px 30px 0;

		width: 295px;
		height: 378px;

		background: url('./img/offer-frame.png');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;

		&__title {
			margin-bottom: 9px;

			font-weight: bold;
			font-size: 18px;
			line-height: 25px;
			text-align: center;
		}

		&__price {
			margin-bottom: 35px;

			font-weight: 900;
			font-size: 55px;
			line-height: 104%;
			text-align: center;

			text-shadow: 0px -2px 7px rgba(255, 212, 100, 0.4);

			background: linear-gradient(
				180deg,
				#ffbe46 0%,
				#ffb53d 6.25%,
				#ef7c31 52.08%,
				#ee6e2f 81.77%,
				#ed632d 100%
			);
			background-clip: text;
			-webkit-text-fill-color: transparent;
		}

		.timer-container {
			padding-left: 10px;

			display: flex;
			align-items: center;

			.timer__label {
				margin-right: 9px;

				font-weight: 600;
				font-size: 14px;
				line-height: 19px;
				text-align: center;

				color: #af96a7;
			}

			.timer {
				padding: 5px 11px 3.27px 12px;

				width: 113px;

				font-weight: 800;
				font-size: 14px;
				line-height: 19px;

				text-align: center;

				color: #d12867;

				background: #45042a;

				border-radius: 19px;
			}
		}
	}

	&-buy {
		position: relative;
		top: -35px;

		margin-left: 20px;

		display: flex;
		flex-direction: column;
		&__description {
			margin-top: 21px;
			max-width: 400px;

			font-size: 12px;
			line-height: 17px;
			text-align: center;
			opacity: 0.5;
		}
	}
}
